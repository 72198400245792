import type { ListJs, ResetPasswordValidateJs, ResponseObject, SsoResponse, TimeZoneJs } from "@/generated/models";
import type { DoLoginModel } from "@/services/models/account";
import type { AxShareHostsConfig } from "@/services/models/config";
import type { RequestOptions } from "ls/api/common/server";
import type { RouteLocationNormalized, RouteLocationResolvedGeneric } from "vue-router";
import type { ChangeAccountInfoModel, ResetPasswordModel, SignUpModel } from "./utils";
import { authParamName, redirectParamName } from "@/common/axshare/routing";
import { isElectron } from "@/common/environment";
import { testSameOrigin } from "@/common/lib/browser";
import { Main } from "@/desktop/events";
import { objectToFormData } from "@/services/utils/formData";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { joinURL } from "ufo";
import { computed, ref, watch } from "vue";
import { authToken } from "./authTokenStorage";
import { useAxiosInstance } from "./useAxiosInstance";

export function useAxureCloudAccountService() {
  const config = useAxureCloudConfig();

  const accountServerUrl = ref<string>();
  const accountServer = useAxiosInstance(accountServerUrl);

  const appServerUrl = ref<string>();
  const appServer = useAxiosInstance(appServerUrl);

  const clientAppUrl = ref<string>();

  const isSameAsApiHost = computed(() => {
    if (!accountServerUrl.value || !appServerUrl.value) return true;
    return testSameOrigin(accountServerUrl.value, appServerUrl.value);
  });

  const loginBaseUrl = computed(() => {
    if (!isSameAsApiHost.value) {
      if (!accountServerUrl.value) {
        return undefined;
      }
      return joinURL(accountServerUrl.value, "/app/login");
    }

    if (clientAppUrl.value) {
      return joinURL(clientAppUrl.value, "login");
    }

    return joinURL(window.location.origin, "/app/login");
  });

  watch(config, value => {
    if (!value) {
      appServerUrl.value = undefined;
      accountServerUrl.value = undefined;
      clientAppUrl.value = undefined;
      return;
    }

    appServerUrl.value = value.AxShareHostSecureUrl;
    accountServerUrl.value = value.AccountServiceSecureUrl;
    clientAppUrl.value = value.AxShareClientUrl;
  }, { immediate: true });

  async function auth() {
    if (isElectron) {
      const config: AxShareHostsConfig = await window.AxureCloudNative.ipc.invoke(Main.ConfigGet);
      if (config.authToken) {
        authToken.set(config.authToken);
      }
    }

    const response = await appServer.value.post("user/oauth2");
    const sso = response.data as SsoResponse;

    if (sso.success && sso.authToken) {
      authToken.set(sso.authToken);
    }

    return sso;
  }

  async function login(email: string, password: string, redirect?: string) {
    const model: DoLoginModel = {
      loginEmail: email.trim(),
      loginPassword: password,
      altPassword: "",
      passwordBlank: !password,
      accountService: false,
      clearPass: true,
      staySignedIn: true,
      redirect,
    };

    // ASK: can we use our existing "exec" call to enable support of "redirects"?
    const response = await accountServer.value.post("user/doSignIn", objectToFormData(model));
    const sso = response.data as SsoResponse;

    if (sso.success) {
      if (sso.authToken) {
        authToken.set(sso.authToken);
      }

      if (sso.redirecturl) {
        window.location.href = sso.redirecturl;
        return;
      }
    }

    return sso;
  }

  async function logout() {
    const response = await accountServer.value.post("user/logout?isAjax=true");

    authToken.clear();

    return response;
  }

  async function updateUserProfileName(name: string) {
    const formData = objectToFormData({ name });
    const response = await appServer.value.post("user/updateUserProfileName", formData);
    return response.data as ResponseObject;
  }

  async function uploadUserProfileImg(fileToUpload: File) {
    const formData = objectToFormData({ fileToUpload });
    const response = await accountServer.value.post("user/uploadUserProfileImg", formData);
    return response.data as ResponseObject & { imgUrl: string };
  }

  async function deleteUserProfileImg() {
    const response = await accountServer.value.post("user/deleteUserProfileImg");
    return response.data as ResponseObject;
  }

  async function changeAccountInfo(model: ChangeAccountInfoModel) {
    const formData = objectToFormData(model);
    const response = await accountServer.value.post("user/changeAccountInfo", formData);
    const data = response.data as ResponseObject & { authToken: string };
    if (data.success && data.authToken) {
      await refreshAuth(data.authToken);
    }
    return data;
  }

  async function refreshAuth(token: string) {
    authToken.set(token);
    const formData = objectToFormData({ token });
    if (isSameAsApiHost.value) {
      await accountServer.value.post("user/refreshAuth", formData);
    } else {
      await Promise.all([
        accountServer.value.post("user/refreshAuth", formData),
        appServer.value.post("user/refreshAuth", formData),
      ]);
    }
  }

  async function forgotPassword(email: string, target: string, redirect?: string) {
    const response = await accountServer.value.post("user/forgotPassword", objectToFormData({ email, target, redirect }));
    return response.data as ResponseObject;
  }

  async function resetPasswordValidateToken(token: string) {
    const response = await accountServer.value.post("user/resetPasswordValidateToken", objectToFormData({ token }));
    return response.data as ResetPasswordValidateJs;
  }

  async function getTimeZones({ signal }: RequestOptions = {}) {
    const response = await accountServer.value.get("user/getTimeZones", { signal });
    return response.data as ListJs<TimeZoneJs>;
  }

  async function setUserTimeZone(timeZoneId?: string) {
    const baseUtcOffsetMinutes = -(new Date().getTimezoneOffset());
    const formData = objectToFormData({ timeZone: timeZoneId, baseUtcOffsetMinutes });

    const response = await accountServer.value.post("user/setLocalTimeZone", formData);
    return response.data as ResponseObject;
  }

  function getLoginFullUrl(to?: RouteLocationNormalized | RouteLocationResolvedGeneric) {
    if (!loginBaseUrl.value) return undefined;
    const fullLoginUrl = new URL(loginBaseUrl.value);

    const afterAuthRedirectUrl = getAfterAuthRedirectUrl(to);
    fullLoginUrl.searchParams.append(redirectParamName, afterAuthRedirectUrl);
    return fullLoginUrl.href;
  }

  function getAfterAuthRedirectUrl(to?: RouteLocationNormalized | RouteLocationResolvedGeneric) {
    const clientAfterAuthUrl = new URL("/app/login", window.location.origin);
    clientAfterAuthUrl.searchParams.append(authParamName, "true");

    const redirectParam = to?.query[redirectParamName];
    let redirect = Array.isArray(redirectParam) ? redirectParam[0] : redirectParam;
    if (!redirect && to && to.fullPath !== undefined) {
      redirect = to.fullPath.split("#")[0];
    }
    clientAfterAuthUrl.searchParams.append(redirectParamName, redirect || "");

    const appServerAuthUrl = new URL(`${appServerUrl.value}/user/axureauth`);
    appServerAuthUrl.searchParams.append(redirectParamName, clientAfterAuthUrl.href);
    return appServerAuthUrl.href;
  }

  async function signUp(options: SignUpModel) {
    const response = await accountServer.value.post("user/create", objectToFormData(options));
    return response.data as SsoResponse;
  }

  async function resetPassword(options: ResetPasswordModel) {
    const response = await accountServer.value.post("user/resetpasswordspa", objectToFormData(options));
    return response.data as SsoResponse;
  }

  return {
    auth,
    login,
    logout,
    updateUserProfileName,
    uploadUserProfileImg,
    deleteUserProfileImg,
    changeAccountInfo,
    forgotPassword,
    getTimeZones,
    setUserTimeZone,
    isSameAsApiHost,
    getLoginFullUrl,
    signUp,
    resetPasswordValidateToken,
    resetPassword,
  };
}
