<script setup lang="ts">
import { DialogTitle, type DialogTitleProps, useForwardProps } from "radix-vue";

const props = defineProps<DialogTitleProps>();
const forwarded = useForwardProps(props);
</script>

<template>
  <DialogTitle v-bind="forwarded" class="text-balance text-lg/6 font-semibold">
    <slot />
  </DialogTitle>
</template>
