import { asError } from "@/common/lib";
import { RpErrorTypes } from "@/generated/models";
import { createSharedComposable } from "@vueuse/core";
import { computed, ref } from "vue";

function useRpLicenseAuditFn() {
  const error = ref<Error | undefined>();
  async function executeLicenseCheckedEndpoint<T>(endpoint: () => Promise<T>): Promise<T> {
    try {
      return await endpoint();
    } catch (e) {
      const err = asError(e);
      if (isLicenseAuditFailedError(err)) {
        error.value = err;
      }
      throw e;
    }
  }

  function clear() {
    error.value = undefined;
  }

  const isFailed = computed(() => !!error.value);
  return {
    executeLicenseCheckedEndpoint,
    clear,
    isFailed,
  };
}

function isLicenseAuditFailedError(error: Error | undefined) {
  if (!error || !error.cause) return false;
  if (typeof error.cause !== "object") return false;
  return "ErrorType" in error.cause && error.cause.ErrorType === RpErrorTypes.LicenseAuditFailed;
}

export const useRpLicenseAudit = createSharedComposable(useRpLicenseAuditFn);
