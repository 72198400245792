<script setup lang="ts">
import { rpLgUrl } from "ls/components/icons";
import SimpleButton from "ls/components/simple-button/SimpleButton.vue";
import { SimpleDialog, SimpleDialogContent, SimpleDialogDescription, SimpleDialogHeader, SimpleDialogTitle } from "ls/components/simple-dialog";

import { useRpLicenseAudit } from "./useLicenseAudit";

const { clear, isFailed } = useRpLicenseAudit();
function handleDialogOpen(open: boolean) {
  if (!open) clear();
}
</script>

<template>
  <SimpleDialog
    v-if="isFailed"
    :open="isFailed"
    @update:open="handleDialogOpen"
  >
    <SimpleDialogContent>
      <SimpleDialogHeader class="items-center gap-4 mt-2">
        <img :src="rpLgUrl" class="size-12 shrink-0" />
        <SimpleDialogTitle>Axure RP</SimpleDialogTitle>
        <SimpleDialogDescription class="sr-only">
          Valid Axure RP license required
        </SimpleDialogDescription>
      </SimpleDialogHeader>
      <span class="text-center">
        Build realistic, functional prototypes with Axure RP, and<br />
        publish to Axure Cloud to share and gather feedback. Once you<br />
        have a trial or license, you will be able to publish RP files.
      </span>
      <SimpleButton
        variant="primary"
        class="max-w-80 mx-auto w-full"
        href="https://www.axure.com/"
      >
        Learn more and download a free trial
      </SimpleButton>
      <p class="text-pretty text-center text-sm text-slate-500">
        If you have a license, make sure it is assigned to this account in<br />
        <a href="https://portal.axure.com/manage" class="underline">Axure Portal</a> or try publishing from RP. <a href="https://www.axure.com/publish-help" class="underline">Need help?</a>
      </p>
    </SimpleDialogContent>
  </SimpleDialog>
</template>
